import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`TODO`}</p>
    <p>{`options:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`pass arguments through argv (`}<inlineCode parentName="p">{`rjs build client -- --apiUrl=https://api.site.com`}</inlineCode>{`) -> `}<inlineCode parentName="p">{`import argv from '@reworkjs/core/argv';`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`use `}<inlineCode parentName="p">{`preval.macro`}</inlineCode>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`import preval from 'preval.macro';

// language=JavaScript
const IS_STAGING = preval\`
  module.exports = process.env.IS_STAGING === 'true';
\`;
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`use `}<inlineCode parentName="p">{`env-loader`}</inlineCode></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      